import Vue from 'vue'

export default Vue.directive('google-signin-button', {
  bind: function (el, binding, vnode) {
    CheckComponentMethods()
    let clientId = binding.value.clientId
    let Scope = binding.value.scope
    let googleSignInAPI = document.createElement('script')
    googleSignInAPI.setAttribute('src', 'https://accounts.google.com/gsi/client')
    googleSignInAPI.setAttribute('async', true)
    googleSignInAPI.setAttribute('defer', true)
    document.head.appendChild(googleSignInAPI)
    googleSignInAPI.onload = InitGoogleButton

    function InitGoogleButton() {
      const client = google.accounts.oauth2.initCodeClient({
        client_id: clientId,
        scope: Scope,
        ux_mode: 'popup',
        callback: signInCallback
      })
      client.requestCode()
    }

    function signInCallback(googleUser) {
      vnode.context.OnGoogleAuthSuccess(googleUser)
    }

    function OnSuccess(googleUser) {
      vnode.context.OnGoogleAuthSuccess(googleUser.getAuthResponse().id_token)
      googleUser.disconnect()
    }

    function Onfail(error) {
      vnode.context.OnGoogleAuthFail(error)
    }

    function CheckComponentMethods() {
      if (!vnode.context.OnGoogleAuthSuccess) {
        throw new Error('The method OnGoogleAuthSuccess must be defined on the component')
      }

      if (!vnode.context.OnGoogleAuthFail) {
        throw new Error('The method OnGoogleAuthFail must be defined on the component')
      }
    }
  }
})
